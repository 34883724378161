import React from 'react'
import '../Component/Slider.css'
import about1 from '../images/1.jpg'
import about2 from '../images/banner-2.jpg'
import about3 from '../images/banner-3.jpg'
import about4 from '../images/banner-4.jpg'
const Slider = () => {

    const colors = [about1, about2, about3,about4];
    const delay = 2500;
    
    function Slideshow() {
      const [index, setIndex] = React.useState(0);
      const timeoutRef = React.useRef(null);
    
      function resetTimeout() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }
    
      React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === colors.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {
          resetTimeout();
        };
      }, [index]);
    
      return (
        <div className="slideshow">
          <div
            className="slideshowSlider"
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            {colors.map((backgroundColor, index) => (
              <img
                className="slide"
                key={index}
                src={backgroundColor}
                alt=''
              ></img>
            ))}
          </div>
    
          <div className="slideshowDots">
            {colors.map((_, idx) => (
              <img
                key={idx}
                className={`slideshowDot${index === idx ? " active" : ""}`}
                alt=''
                onClick={() => {
                  setIndex(idx);
                }}
              ></img>
            ))}
          </div>
        </div>
      );
    }
    

  return (
    <Slideshow />
 
   
  )
}

export default Slider
