import React from 'react'
import SildeHeader from './SildeHeader'
const Mixi = () => {
  return (
    <div>
    <section id="page-title" class="" data-parallax-image="images/contact-banner.jpg" style={{backgroundPosition:"right;"}}>
    <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(images/contact-banner.html) repeat scroll 0% 0px;"}}></div>
        <div class="container">
            <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                <div class="innerBanerText text-center">
                    <span class="firstColor"><span style={{color:"#FFF;"}}> All Mixi/Mixi Jars Repairing  </span>   </span> <span class="secondColor">  </span>
                    <br/>
                    <span class="secondColor">  </span>
                </div>
            </div>
        </div>
    </section>
    <section class="p-t-40 ">
    <div class="container">
      <div class="col">
    <SildeHeader/>
    <div class="col-md-9" >
                	
    <h2 style={{color:"#f47629;"}}>All Mixi/Mixi Jars Repairing </h2>
    <p> <img src="images/mixi-ser-hero.jpg" class="img-responsive" style={{border: "1px #CCC solid;"}}/> </p>
    
    <p style={{color:"#222;"}}>Mixi has become essential kitchen appliance due to its capacity in executing complex tasks in seconds. Right from grinding onions to fruit juices, mixi does everything. Life without it can’t be imagined. If due to some reason, your mixi stopped working, no need to panic because we are here to help you. </p>
    
    
    <p style={{color:"#222;"}}>If you are not sure what to do when its not working? Don’t do anything, call Ram Electricals.Ram Electricals Mixi / Mixi Jars repairing service brings customer satisfaction. Our electricians are always ready to help you according to your need at your home at your convenient time. At Ram Electricals we can repair all brands of Mixis including Philips, LG, Daikin, Samsung, Whirlpool, Havells, Blue Star, Prestige, Daikin, Panasonic, Maharaja, Bajaj, Preethi and more. You no need to visit a repair shop for genuine technician. You can now repair your Mixi or Mixi jar at your home.  </p>

<p style={{color:"#222;"}}> Our technician will visit your home and repair your appliance at your home. We specialize in both Mixi sales and services. With our expert technicians, we keep ourself updated with latest models and makes so we can fix any problem that should arise. With just a phone call we will be at your service and making your appliance working. Our Mixi / Mixi jar repair services are very reasonable.  </p>


<a href="tel:8077115322" class="btn btn-color " ><span>Order Service Now</span></a>
<a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a>
</div>

    </div>
    </div>
    </section>
    </div>
  )
}

export default Mixi
