import React from 'react'
import '../Component/Fans.css'
import SildeHeader from './SildeHeader'

const Fan = () => {
  return (
    <div>
    <section className="p-t-40 ">
    <div className="container">
      <div className="col">
        
       <SildeHeader/>
        <div className="col-md-9" >
            
            <h2 style={{color:"#f47629;"}}>All Fans Repairing </h2>
            <p> <img src="images/fan-ser-hero.jpg" className="img-responsive" style={{border: "1px #CCC solid;"}}/> </p>
            
            <p style={{color:"#222;"}}>Ram Electricals repair all types of fans including ceiling fans, tower fans, stand fans and box fans. When your ceiling fan is no longer working after several attempts what do you do? Most of users will throw it out and buy a new one. The best option is call Ram Electricals. Our fan repair technicians can get it working again. We are experts in installing various ceiling fans. Call us today for your fans repairing.  </p>
            
            
            <p style={{color:"#222;"}}>Fans Repairing should be done by experienced electrician and required specific tools to do it. What included in fans repairing heating problem in fan motor, faulty fan, loose connections, noise problem, fan regulator not working, fan vibrating, fan not running on etc. We provide fast and effective installation of fans and repair services so your fan will work again in no time. Repairing of fans on your own can vulnerable to injury, so make sure to get service from professional. </p>

    <p style={{color:"#222;"}}>Our electricians are experts and boast an experience of 10+ years in various fields and are capable in repairing all type of fans. We take necessary steps to ensure you your fans are repaired properly. When you reach us, we assure you to get your service done on time. If you would like to take our service fill out our Register A Technician Form or call us on +1-800-765-4321.  </p>


    <p style={{color:"#222;"}}><b>Book Online Fan Repair Service </b> <br/>
We provide online fan repair service at your door step.  Avail Fan repair online services from experienced electricians. 
</p>



<a href="tel:8077115322" class="btn btn-color " ><span>Order Service Now</span></a>
<a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a>
        
        </div>
  
      </div>
    </div>
</section>
    </div>
  )
}

export default Fan
