import React from 'react'
import logo from '../images/ElECTRAMEN.png'


const Footer = () => {
  return (
    <>
    <div className="footer-top">
    <div className="container">
      <div className="row">
        <div className="col-sm-4 p-4">
          <div id="text-21" className="">
            <div className="textwidget">
              <p>24/Hour Emergency Service</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 p-4">
          <div id="text-20" className="">
            <div className="textwidget">
              <p>Free Consultations</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 p-4">
          <div id="text-19" className="">
            <div>
            <a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <footer id="footer" className="background-dark text-grey" style={{backgroundColor:"#252936"}}>
    <div className="footer-content">
        <div className="container">
            <div className="row">
            
            <div className="col-md-3">
                    <img src={logo} alt="" style={{width:'100px',height:'80px',borderRadius:'150px'}}/>
                    <p>© 2023 ElectraMen-Electrical Products Service Point.<br/> All Rights Reserved.</p>
                    <div className="social-icons social-icons-border float-left m-t-20">
                            <ul>
                                <li className="social-facebook"> <a href="https://www.facebook.com/profile.php?id=100008671355917"><i className="fa fa-facebook"></i></a></li>
                                <li className="social-twitter"> <a href="https://www.facebook.com/profile.php?id=100008671355917"><i className="fa fa-twitter"></i></a></li>
                                <li className="social-gplus"> <a href="https://www.facebook.com/profile.php?id=100008671355917"> <i className="fa fa-google-plus"></i> </a> </li>
                            </ul>
                        </div>
              </div>     
              
              {/*
                
                <div className="col-md-3">
                    <div className="widget clearfix widget-categories">
                        <h4 className="widget-title">Quick as</h4>
                        <ul className="list list-arrow-icons">
                            <li><a href="/About">About us</a> </li>
                            <li><a href="/About">Contact us</a></li>
                            <li><a href="/">FAQ</a></li>
                            <li> <a href="/"> Terms and Conditions </a> </li>
                            <li> <a href="/"> Privacy Policy </a> </li>
                        </ul>
                    </div>
                </div>
                
                <div className="col-md-3">
                    <div className="widget clearfix widget-categories">
                        <h4 className="widget-title">Our Services</h4>
                        <ul className="list list-arrow-icons">
                        <li> <a href="/FansRepairing" className="active">All Fans Repairing  </a> </li>
                        <li> <a href="/Mixi"> Mixi/Mixi Jars Repairing</a>  </li>
                        <li> <a href="/Cooler"> Cooler Repairing</a>  </li>
                        <li> <a href="/Cooker"> Rice Cooker Repairing</a>  </li>
                        <li> <a href="/AC"> AC Repairing</a>  </li>
                        <li> <a href="/Housewiring"> House Wiring</a>  </li>
                        <li> <a href="/Inverter"> Inverter Connections & Repairing </a>  </li>
                        <li> <a href="/Light_fixing"> Lights Fixing </a>  </li>
                        <li> <a href="/Motors"> Pumps & Motors </a>  </li>
                         </ul>
                    </div>
                </div>

                

                <div className="col-md-3">
                    <div className="widget clearfix widget-categories">
                        <ul className="contact-list">
                           <li> <span className="list-label"><b>P</b>hone: </span> <a href='tel:8077115322'> +91-8077115322</a>  </li>
                          
                           <li>	<span className="list-label"><b>E</b>mail: </span> <a href="mailto:jaydeepyadav.jcmst@gmail.com">jaydeepyadav@gmail.com</a></li>
                           <li>	<span className="list-label"><b>A</b>ddress: </span> <span className="text"> plot no:111, Greater Noida India</span></li>
                           
                        </ul>
                    </div>
                </div>
*/}
            </div>
        </div>
    </div>
    
</footer>


 


<a id="goToTop" className="scroll-to" href="body.html"><i className="fa fa-angle-up top-icon"></i><i className="fa fa-angle-up"></i></a>


<ul className='sticky'>

    <a 
        href="tel:8077115322"
        className="whatsapp_floatt"
        
        rel="noopener noreferrer"
      >
        <i className="fa fa-phone mt-3 ff"></i>
      </a>
    
    <a 
        href="https://wa.me/+919720610972"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>   
 </ul>
    </>
  )
}

export default Footer
