import React from 'react'
import { Link } from 'react-router-dom'

const SildeHeader = () => {
  return (
    <div>
    
      
      <div className="col-md-3">
          <div className="side-links">
              <ul> 
                <li> <Link to="/FansRepairing" className="active">All Fans Repairing  </Link> </li>
                <li> <Link to="/Mixi"> Mixi/Mixi Jars Repairing</Link>  </li>
                <li> <Link to="/Cooler"> Cooler Repairing</Link>  </li>
                <li> <Link to="/Cooker"> Rice Cooker Repairing</Link>  </li>
                <li> <Link to="/AC"> AC Repairing</Link>  </li>
                <li> <Link to="/Housewiring"> House Wiring</Link>  </li>
                <li> <Link to="/Inverter"> Inverter Connections & Repairing </Link>  </li>
                <li> <Link to="/Light_fixing"> Lights Fixing </Link>  </li>
                <li> <Link to="/Motors"> Pumps & Motors </Link>  </li>
              </ul>
          </div>
      </div>
     
    </div>
  )
}

export default SildeHeader
