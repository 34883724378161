import React from 'react'
import SildeHeader from './SildeHeader'

const Motors = () => {
  return (
    <div>
    <section id="page-title" class="" data-parallax-image="images/contact-banner.jpg" style={{backgroundPosition:"right;"}}>
    <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(images/contact-banner.html) repeat scroll 0% 0px;"}}></div>
        <div class="container">
            <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                <div class="innerBanerText text-center">
                    <span class="firstColor"><span style={{color:"#FFF;"}}> Pumps & Motors Repairing </span>   </span> <span class="secondColor">  </span>
                    <br/>
                    <span class="secondColor">  </span>
                </div>
            </div>
        </div>
    </section>
    <section class="p-t-40 ">
    <div class="container">
      <div class="col">
      <SildeHeader/>
      <div class="col-md-9" >
                	
      <h2 style={{color:"#f47629;"}}> Pumps & Motors Repairing </h2>
      <p> <img src="images/motors-ser-hero.jpg" class="img-responsive" style={{border: "1px #CCC solid;"}}/> </p>
      
      <p style={{color:"#222;"}}> At Ram Electricals we pride ourselves in being experts when it comes to pumps and motors repairing. Allow us to help you with all of your pumps and motor repairing needs in Miryalaguda . We are experts in repairing motors and pumps of all sizes. We have experience in fixing all major brands of motors and pumps.  </p>
      
      
      <p style={{color:"#222;"}}> We are experts in meeting our customers requirements by offering pumps and motors repairing. Besides it we render our services at pocket friendly prices. As a leading provider of pumps and motor repairing services, we have gained a reputation for going beyond customer expectation. In addition to solving motor and pump problems we will find the root cause of the issue to prevent from recurring.</p>


      <a href="tel:8077115322" class="btn btn-color " ><span>Order Service Now</span></a>
      <a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a>
  </div>

</div>
</div>
</section>
    </div>
  )
}

export default Motors
