import React from 'react'
import SildeHeader from './SildeHeader'

const Inverters = () => {
  return (
    <div>
    <section id="page-title" class="" data-parallax-image="images/contact-banner.jpg" style={{backgroundPosition:"right;"}}>
    <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(images/contact-banner.html) repeat scroll 0% 0px;"}}></div>
        <div class="container">
            <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                <div class="innerBanerText text-center">
                    <span class="firstColor"><span style={{color:"#FFF;"}}>  </span> Inverter Connections & Repairing  </span> <span class="secondColor">  </span>
                    <br/>
                    <span class="secondColor">  </span>
                </div>
            </div>
        </div>
    </section>
    <section class="p-t-40 ">
    <div class="container">
      <div class="col">
      <SildeHeader/>
      <div class="col-md-9" >
                	
      <h2 style={{color:"#f47629;"}}> Inverter Connections & Repairing </h2>
      <p> <img src="images/inverters-ser-hero.jpg" class="img-responsive" style={{border: "1px #CCC solid;"}}/> </p>
      
      <p style={{color:"#222;"}}> In most cities in India, Inverter has become essential appliance due to lack of power. Like any other electric device it is essential to repair invertors be done by experts. Do you need expert service provider for inverter repair service?  </p>
      
      
<p style={{color:"#222;"}}> Ram Electricals is leading service provider and have skilled technicians to provide you quality services. Ram Electricals is a paramount organization that is engaged in offering inverter connections and repairing services. High skilled and experienced are hired by us who offer services using advanced technology. </p>

<p style={{color:"#222;"}}> Some of common issues like invertor not working, faulty batteries, inverter short circuit and more. Don’t worry about them, we can diagnosis these issues quickly and make your inverter work again. We also provide inverter installation services for all reputed brands like Luminous, Sukam and more. Experience hassle-free inverter repair service by contacting us.  </p>

<p style={{color:"#222;"}}> Our inverter installation & repair services are available in most cities. We provide all our services to domestic and industrial customers at their doorsteps. Few of our repair services includes battery replacement, inverter inspection and inverter installation. We are local service providers we arrive on time and deliver flaw less service. Submit your request and get connected to best local inverter repair service provider.  </p>
<p style={{color:"#222;"}}> <b>Best Inverter repair service </b><br/>
Ram Electrical Services is best renowned firm for Inverter Repair Service. Inverter repair service is handled by expert professionals in electric field. 
</p>



<a href="tel:8077115322" class="btn btn-color " ><span>Order Service Now</span></a>
<a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a>
  </div>

</div>
</div>
</section>
    </div>
  )
}

export default Inverters
