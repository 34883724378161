import React from 'react'
import SildeHeader from './SildeHeader'

const Cooker = () => {
  return (
    <div>
    <section id="page-title" class="" data-parallax-image="images/contact-banner.jpg" style={{backgroundPosition:"right;"}}>
        <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(images/contact-banner.html) repeat scroll 0% 0px;"}}></div>
            <div class="container">
                <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                    <div class="innerBanerText text-center">
                    	<span class="firstColor"><span style={{color:"#FFF;"}}> Rice Cookers Repairing </span>   </span><span class="secondColor">  </span>
                        <br/>
                        <span class="secondColor">  </span>
                    </div>
                </div>
            </div>
        </section>
        <section class="p-t-40 ">
        <div class="container">
          <div class="col">
          <SildeHeader/>
    <div class="col-md-9" >
                	
    <h2 style={{color:"#f47629;"}}> Rice Cookers Repairing </h2>
    <p> <img src="images/cookers-ser-hero.jpg" class="img-responsive" style={{border: "1px #CCC solid;"}}/> </p>
    
    <p style={{color:"#222;"}}> Arranging rice cooker repairing in your area couldn’t be easier. Ram Electricals achieved well known position in market by providing cooker repairing services. Our vast industrial experience has enabled us to offer wide range of cooker repairing services. We make use of advanced techniques in repairing. All our repairing services are liked by our customers due to their timely completion. All our repair services are rendered at reasonable prices. </p>
    
    
    <p style={{color:"#222;"}}> We are here to provide Rice Cooker repairing services for all kinds of makes and models. We provide home services. Our repair only out of brand products. Our skilled team of professionals are capable to service and repair all type of rice cookers. We also make sure that your rice cookers will exhibit high performance without any repairs. Our team is equipped with advanced machinery, tools and high grade spare parts. </p>

<p style={{color:"#222;"}}> To ensure flawless rice cooker repair services our expert team conducts tests and check appliance before providing it to customer. We have gained our customers appreciation as most prominent name in rice cooker repairing service. Our appliance engineers are trained and our rice cooker repairing service includes call out, diagnosis and repair at your home. Rest assured we have a network of fully trained engineers to repair your appliances. We repair all brands and models of cookers includes Panasonic, Pigeon, Prestige, Bajaj, Butterfly and more.  </p>

<p style={{color:"#222;"}}> <b>Contact Electric Rice Cooker Repair </b><br/>
Electric Rice Cooker Repair Service will be available for 24 hours. Customers can get Electric cooker repair and Panasonic rice cooker repair at their home by contacting Ram Electrical Services. <br/><br/>

<b>Call Rice cooker repair near me </b><br/>
To find rice cooker repair shop near me, share your details with Ram Electrical Services. We will connect you with electric rice cooker repair professionals near you. <br/><br/>

<b>How Do You Service Rice Cooker? </b><br/>
If you find any issue with rice cooker, you can reach Ram Electrical Services. We make sure that you will experience optimal service. 

</p>


<a href="tel:8077115322" class="btn btn-color " ><span>Order Service Now</span></a>
<a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a>
</div>
    </div>
    </div>
    </section>
    </div>
  )
}

export default Cooker
