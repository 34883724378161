import React from "react";
import one from "../images/1.jpg";
import Slider from "./Slider";
import Sli from "../Sli";


const Home = () => {
  return (
    <>
    <Slider/>

      <div className="background-grey">
        <div className="container p-t-60 p-b-60">
          <div className="row">
            <div className="col-md-7">
              <h2
                className="text-medium"
                style={{ color: "#f47629", marginBottom: "100px" }}
              >
                You need a Electrician?
              </h2>
              <h4>Register Complaint for Technician </h4>

              <div style={{ padding: "10px" }}>
                <h4 className="m-b-10">
                  {" "}
                  <b>Contact Persons:</b>{" "}
                </h4>
                <div className="row">
                  <div
                    className="col-md-5 col-sm-1"
                    style={{
                      padding: "px",
                      background: "#FFF",
                      display: "inline-block",
                      marginRight: "5px",
                      padding: "10px 20px",
                    }}
                  >
                    <h5 style={{ color: "#f47629" }}>Pulkit Kumar</h5>
                    <div style={{ float: "left", marginRight: "10px" }}>
                      <h5 className="m-b-10">
                        {" "}
                        <b>
                          <i className="fa fa-phone "></i>
                        </b>{" "}
                        +91-8077115322{" "}
                      </h5>
                    </div>
                    <div style={{ float: "left" }}>
                      <h5 className="">
                        {" "}
                        <b>
                          <i className="fa fa-whatsapp whatsapp-color"></i>
                        </b>{" "}
                        +91-9720610972{" "}
                      </h5>
                    </div>
                  </div>
                  <div
                    className="col-md-5 col-sm-1 "
                    style={{
                      padding: "px",
                      background: "#FFF",
                      display: "inline-block",
                      marginRight: "5px",
                      padding: "10px 20px",
                    }}
                  >
                    <h5 style={{ color: "#f47629" }}>
                      Dharmender Choudhary{" "}
                    </h5>
                    <div style={{ float: "left", marginRight: "10px" }}>
                      <h5 className="m-b-10">
                        {" "}
                        <b>
                          <i className="fa fa-phone "></i>
                        </b>{" "}
                        +91-8077115322{" "}
                      </h5>
                    </div>
                    <div style={{ float: "left" }}>
                      <h5 className="">
                        {" "}
                        <b>
                          <i className="fa fa-whatsapp whatsapp-color"></i>
                        </b>{" "}
                        +91-9720610972{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 real-estate-form-container">
              <div className="real-estate-form">
                <h3 className="form-heding">Request Service Today</h3>
                <div
                  className="text-dark p-40 background-dark"
                  style={{ background: "#252936" }}
                >
                  <form
                    className="widget-contact-form"
                    action="http://www.ramelectricalservices.com/include/service-request.php"
                    role="form"
                    method="post"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            aria-required="true"
                            name="widget-contact-form-name"
                            className="form-control required name"
                            placeholder="Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            aria-required="true"
                            name="widget-contact-form-email"
                            className="form-control required email"
                            placeholder="Enter your Email"
                          />{" "}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            aria-required="true"
                            name="widget-contact-form-phone"
                            className="form-control required phone"
                            placeholder="Phone"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            aria-required="true"
                            name="widget-contact-form-address"
                            className="form-control required address"
                            placeholder="Address"
                          />
                        </div>

                        <div className="form-group">
                          <textarea
                            type="text"
                            name="widget-contact-form-complaint"
                            rows="4"
                            className="form-control required"
                            placeholder="Complaint Information "
                          ></textarea>
                        </div>

                        <div className="form-group">
                          <script src="../www.google.com/recaptcha/api.js"></script>
                          <div
                            className="g-recaptcha"
                            data-sitekey="6LefO1EUAAAAAL1vzfHsGF1KokJeCQiwDO4p8N2t"
                          ></div>
                        </div>

                        <div className="form-group">
                          <button
                            className="btn button  black rounded"
                            type="submit"
                            id="form-submit"
                            style={{
                              background: "white",
                              borderColor: "#f47629 !important",
                            }}
                          >
                            Get Service
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="heading heading-center">
            <h2>
              Our <span style={{ color: "#f47629" }}>Services</span>
            </h2>
            <p className="font24 text-center">
              If you have electrical emergency and need an electrician
              immediately, Ram Electricals are <br /> ready to help you anytime
              of the day.{" "}
            </p>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="icon-box  medium  center">
                <div className="icon">
                  <a href="#">
                    <i className="fa fa-1"></i>
                  </a>
                </div>
                <h3>Electrical</h3>
                <p>
                  {" "}
                  Ram Electricals is expert in New wiring, remodelling,
                  additions and renovations.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="icon-box  medium  center">
                <div className="icon">
                  <a href="#">
                    <i className="fa fa-2"></i>
                  </a>
                </div>
                <h3> Mixi Jars Repairing</h3>
                <p>
                  Ram Electricals provide you excellent Mixi Jar Repairing
                  services at your relevant time.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="icon-box  medium  center">
                <div className="icon">
                  <a href="#">
                    <i className="fa fa-3"></i>
                  </a>
                </div>
                <h3> Air Conditioning </h3>
                <p>
                  Need to schedule an emergency AC Repair? When you need an AC
                  repair service immediately, contact us.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-box  medium  center">
                <div className="icon">
                  <a href="#">
                    <i className="fa fa-4"></i>
                  </a>
                </div>
                <h3>Fans Repairing </h3>
                <p>
                  Our electricians are experts in ceiling fan fitting,
                  installation, fixing and checking ceiling fan& its regulator
                  and repair fans.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="icon-box  medium  center">
                <div className="icon">
                  <a href="#">
                    <i className="fa fa-5"></i>
                  </a>
                </div>
                <h3>Lights Fixing</h3>
                <p>
                  We are here to help you withmost efficient solutions. We
                  execute electrical wiring plan for well-functioning of
                  lighting.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="icon-box  medium  center">
                <div className="icon">
                  <a href="#">
                    <i className="fa fa-6"></i>
                  </a>
                </div>
                <h3>House Wiring</h3>
                <p>
                  Our electricians provide expert house wiring services from new
                  wiring to wiring replacement, wiring upgrade and repair.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

     <Slider/>

      <section>
        <div className="container">
          <p>
            {" "}
            <img
              src="images/trusted.jpg"
              alt=""
              className="img-responsive"
            />{" "}
          </p>
        </div>
      </section>

    
    </>
  );
};

export default Home;
