import React from 'react'

const About = () => {
  return (
    <div>
    <section id="page-title" class="" data-parallax-image="images/about-banner.jpg" style={{backgroundPosition:"right;"}}>
    <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(_images/about-banner.html) repeat scroll 0% 0px;"}}></div>
        <div class="container">
            <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                <div class="innerBanerText text-center">
                    <span class="firstColor"> ABOUT US  </span> <span class="secondColor">  </span>
                    <br/>
                    <span class="secondColor">  </span>
                </div>
            </div>
        </div>
    </section>

   

  
<section>
<div class="container">
    <div class="heading heading-center m-b-30">
     
        <p class="font24 text-center" style={{lineHeight:"36px;"}}>Ram Electricals brings together electric product sales and services for the needs of market.<br/> The manufacturer that we represent are most popular brands.</p>
    </div>
    
    <div class="row">
        
            <div class="col-md-6 col-sm-12 m-t-30">
               <img src="images/about-hero.jpg" class="img-responsive"/>
            </div>
            
            <div class="col-md-6" style={{padding:"40px 0 0 20px;"}}>
                <div class="mybox">
                    <p style={{marginBottom:"15px;", color:"#f47629;", fontSize:"18px;"}}>The modern day generation everything grounded with electrical without it is unimaginable. Just imagine a day without power can immediately put you not able to find your belongings in dark.  </p>
                    
                     <p style={{marginBottom:"15px;", color:"#888;", fontSize:"18px;"}}> To comply with well circuited electricity is preventing situations that might disturb our day. During some cases, we even pay huge amount to our home wiring. In such cases not having an electrician number can be horrible. </p>
                     
                     <p style={{marginBottom:"15px;", color:"#888;", fontSize:"18px;"}}> If you are one of those individuals with work busy or in need of an electrician at your service, Ram Electricals has your back. Be it electrical wiring or appliances repairing or even Air Conditioners repairing, we supply you only background verified electrical technician or contractor to deliver required electrical services.  </p>
                     
                    <p style={{marginBottom:"15px;", color:"#888;", fontSize:"18px;"}}> Apart from reaching our electricians online, we also deliver home services in Miryalaguda. We make sure 100% satisfaction. When it comes to fans, Mixi jars, AC or any other electrical repairs. Ram Electricals offers a comprehensive portfolio of electrical solutions including sales and services. </p>
                     
                    <p style={{marginBottom:"15px;", color:"#888;", fontSize:"18px;"}}> Why Ram Electricals Electrical Services Online? </p>
                    
                    <ul class="list-icon list-icon-check">
                        <li>We promise 100% customer satisfaction </li>
                        <li>Hassle free services at competitive prices </li>
                        <li>Book our online electrician at your convenient time and date </li>
                        <li>One stop solution to all Electrical repair needs </li>
                    </ul>

<b>Book Electrician Near Me at Doorstep </b> <br/>
Book certified electricians in Miryalaguda. Free service will be provided if the same problem arises in next 7 days. Contact us now to get quality electrical services. <br/> <br/>
<b>Book 24 Hour Electrician Near Me </b> <br/>
Find and Book best electrician near you who provide 24 Hours Electrician service. Find electrician near you who provide service at cheap price. <br/><br/>
<b>Electrician Near Me Phone Number </b><br/>
Ram Electrical Services is best electrical service provider. Need electrician in miryalaguda? Contact us. Electrician near you phone number is +91-924697688. <br/><br/>
<b>Find Electrician Near Me Open Now </b><br/>
Hire electricians in Miryalaguda. Find electrician near you open now in in Miryalaguda and book for best electrician and relax. Experience the hassle free electrician service in in Miryalaguda. <br/><br/>
<b>Electricians in Miryalaguda</b><br/>
Ram Electrical Services provides electric repair service in Miryalaguda. Our range of services includes electricians in Miryalaguda, electricians in Vanasthalipuram, electricians in LB Nagar, wanted electricians in Miryalaguda, book electrician online in Miryalaguda, electrician near me 

                    
                </div>
            
            </div>
      
        </div>
    
</div>

</section>


   

    

<section> 
 <div class="container">
    <p> <img src="images/trusted.jpg" /> </p>
</div>
</section>
    </div>
  )
}

export default About
