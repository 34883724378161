import React,{useState} from 'react'
import Nabvar from './Nabvar'
import { Link } from 'react-router-dom'
import logo from '../images/ElECTRAMEN.png'

const Header = () => {
   
  return (
    <>

    
   <nav className="navbar navbar-dark navbar-expand-lg bg-dark fs-5">
   <Link className="navbar-brand" to="#"><h1 style={{color:'white'}}>EPSP</h1></Link>
   <Link to="/" className="logo" data-dark-logo="images/ElECTRAMEN.png">
       <img src={logo} alt="Logo" style={{width:'100px',height:'80px',borderRadius:'150px'}}/> 
   </Link> 
    
   <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
     <span className="navbar-toggler-icon"></span>
   </button>
 
   <div className="collapse navbar-collapse" id="navbarSupportedContent">
     <ul className="navbar-nav mr-auto p-4">
      <li><Link to="/" className="nav-link">Home</Link></li>
                                    <li className="nav-item"><Link to="/About" className="nav-link">About us</Link></li>
                                    <li className="nav-item"><Link to="/FansRepairing" className="nav-link">Products Sales</Link></li>
                                    <li className="nav-item dropdown"> <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Home Services</Link>
                                        <ul className="dropdown-menu fs-5" aria-labelledby="navbarDropdown">
                                            <li> <Link to="/FansRepairing"className="dropdown-item"> All Fans Repairing </Link>  </li>
                                            <li> <Link to="/Mixi" className="dropdown-item"> Mixi/Mixi Jars Repairing</Link>  </li>
                                            <li> <Link to="/Cooler" className="dropdown-item"> Cooler Repairing</Link>  </li>
                                            <li> <Link to="/Cooker" className="dropdown-item"> Rice Cooker Repairing</Link>  </li>
                                            <li> <Link to="/AC" className="dropdown-item"> AC Repairing</Link>  </li>
                                            <li> <Link to="/Housewiring" className="dropdown-item"> House Wiring</Link>  </li>
                                            <li> <Link to="/Inverter" className="dropdown-item"> Inverter Connections & Repairing </Link>  </li>
                                            <li> <Link to="/Light_fixing" className="dropdown-item"> Lights Fixing </Link>  </li>
                                            <li> <Link to="/Motors" className="dropdown-item"> Pumps & Motors </Link>  </li>
                                        </ul>
                                        </li>
                                        <li className="nav-item"><Link to="/About" className="nav-link">Contact us</Link></li>
      
     </ul>
     {/*
     <form className="form-inline my-2 my-lg-0">
       <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
       <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
     </form>
   */}
   
     </div>
     
    
 </nav>



   

    </>
  )
}

export default Header
