import React from 'react'
import SildeHeader from './SildeHeader'

const Ac = () => {
  return (
    <div>
    <section id="page-title" class="" data-parallax-image="images/contact-banner.jpg" style={{backgroundPosition:"right;"}}>
    <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(images/contact-banner.html) repeat scroll 0% 0px;"}}></div>
        <div class="container">
            <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                <div class="innerBanerText text-center">
                    <span class="firstColor"><span style={{color:"#FFF;"}}>Air Conditioners Repairing</span>   </span> <span class="secondColor">  </span>
                    <br/>
                    <span class="secondColor">  </span>
                </div>
            </div>
        </div>
    </section>

    <section class="p-t-40 ">
    <div class="container">
      <div class="col">
      <SildeHeader/>

    <div class="col-md-9" >
                	
    <h2 style={{color:"#f47629;"}}> Air Conditioners Repairing </h2>
    <p> <img src="images/ac-ser-hero.jpg" class="img-responsive" style={{border: "1px #CCC solid;"}}/> </p>
    
    <p style={{color:"#222;"}}> Preparing for summer? Make sure your AC is working with onset of hot and humid weather. Call Ram Electricals to experience first rate AC Repair Services. With us you will be connected to a qualified AC Repair expert. So you can be assured of reliable work that gives you value for money. Few of AC Repair Services you can avail from us  include  </p>
    
    <ul>
        <li> AC Installation  		</li>
        <li> AC Uninstallation   	</li>
        <li> Dr AC Servicing 		</li>
        <li> Wet AC Servicing 		</li>
        <li> AC Gas Fill			</li>
        <li> AC Repair Service		</li>
    </ul>
    
    
    <p style={{color:"#222;"}}> By dialling our phone number, you can pick a expert professional on our site and get your job done. We are experts in delivering 100% customer satisfaction and 0% hassle-free service. We hand pick our exerts after thoroughly checking their background.  </p>

<p style={{color:"#222;"}}> Be it split AC, window AC, central AC, you can assured for satisfaction. Who says home services are expensive? When you see our professionals working on your area and you will feel that charges are reasonable. This makes our home service very cost effective.  </p>


<p style={{color:"#222;"}}> We are accessible in a few taps on phone. Our professionals are ready to service you as per your schedule.  We make sure that we source skilled professionals to carry out AC Repairs. So if you feel not comfortable with your faulty AC, we are just a phone call away.  </p>


<a href="tel:8077115322" class="btn btn-color " ><span>Order Service Now</span></a>
<a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a>
</div>

</div>
</div>
</section>
    </div>
  )
}

export default Ac
