import logo from './logo.svg';
import './App.css';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Home from './Component/Home';
import javas from './Sli'
import { Helmet } from 'react-helmet';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import About from './Component/About';
import Products from './Component/Products';
import FansRepairing from './Component/FansRepairing';
import Mixi from './Component/Mixi';
import Cooler from './Component/Cooler';
import Cooker from './Component/Cooker';
import Ac from './Component/Ac';
import House_wiring from './Component/House_wiring';
import Inverters from './Component/Inverters';
import Light_fixing from './Component/Light_fixing';
import Motors from './Component/Motors';


function App() {
  return (
    <BrowserRouter>
    <div className="App">
    <Header/>
    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/About' element={<About/>}/>
    <Route path='/FansRepairing' element={<FansRepairing/>}/>
    <Route path='/Mixi' element={<Mixi/>}/>
    <Route path='/Cooler' element={<Cooler/>}/>
    <Route path='/Cooker' element={<Cooker/>}/>
    <Route path='/AC' element={<Ac/>}/>
    <Route path='/Housewiring' element={<House_wiring/>}/>
    <Route path='/Inverter' element={<Inverters/>}/>
    <Route path='/Light_fixing' element={<Light_fixing/>}/>
    <Route path='/Motors' element={<Motors/>}/>
    </Routes>
     
     <Footer/>
    </div>
    </BrowserRouter>
  );
}

export default App;
