import React from 'react'
import SildeHeader from './SildeHeader'

const Light_fixing = () => {
  return (
    <div>
    <section id="page-title" class="" data-parallax-image="images/contact-banner.jpg" style={{backgroundPosition:"right;"}}>
    <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(images/contact-banner.html) repeat scroll 0% 0px;"}}></div>
        <div class="container">
            <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                <div class="innerBanerText text-center">
                    <span class="firstColor"><span style={{color:"#FFF;"}}>  </span>  Lights-Fixing  </span> <span class="secondColor">  </span>
                    <br/>
                    <span class="secondColor">  </span>
                </div>
            </div>
        </div>
    </section>
    <section class="p-t-40 ">
    <div class="container">
      <div class="col">
      <SildeHeader/>
      <div class="col-md-9" >
                	
      <h2 style={{color:"#f47629;"}}> Lights-Fixing </h2>
      <p> <img src="images/lights-ser-hero.jpg" class="img-responsive" style={{border: "1px #CCC solid;"}}/> </p>
      
      <p style={{color:"#222;"}}> Are you looking for best lighting that makes your office or home look great? Ram Electricals is a company where you will get all kinds of services like lights fixing is one of those in which we are experts. We have skilled and expert technicians for lights fixing. We fix all kinds of lights. Our light fixing service list includes replacement of lights, electric short circuit fixing, residential light fixing and installation services. </p>
      
      
      <p style={{color:"#222;"}}> If you have any issue which is not listed here don’t hesitate to contact us we provide 24x7 service so our skilled technicians will be at your doorstep when you need it. We deliver quality services on time. Our technicians are well trained in lights fixing and help you if you are confused in selection of types of lights including table lamps, roof lamps, track lighting, creator lamps, ceiling fixtures, old fashioned lamps and more.  </p>

<p style={{color:"#222;"}}> Above listed are few lights that we repair, if you have any issue with your lights then call our professional light repair service.You can now have your dream lighting works done at your home. We help our customers in getting best lights. Our skilled experts are always ready to make your home or office with lightings. If you require light replacement or want to access your lighting system, contact us. We can arrange an appointment at your convenient time.  </p>

<a href="tel:8077115322" class="btn btn-color " ><span>Order Service Now</span></a>
<a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a> 
  </div>

</div>
</div>
</section>
    </div>
  )
}

export default Light_fixing
