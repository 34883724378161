import React from 'react'
import '../Component/Fans.css'
import Fan from './Fan'

const FansRepairing = () => {
  return (
    <div>

    




    <section id="page-title" class="" data-parallax-image="images/contact-banner.jpg" style={{backgroundPosition:"right;"}}>
    <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(images/contact-banner.html) repeat scroll 0% 0px;"}}></div>
        <div class="container">
            <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                <div class="innerBanerText text-center">
                    <span class="firstColor"><span style={{color:"#FFF;"}}> HOME </span> SERVICES  </span> <span class="secondColor">  </span>
                    <br/>
                    <span class="secondColor">  </span>
                </div>
            </div>
        </div>
    </section>

   

  
   <Fan/>

   
<section class="background-image background-overlay-one"  data-parallax-image="images/testimonials-bg.jpg" style={{padding:"20px 0;"}}>
    
    <div class="heading heading-center m-t-20 m-b-20 ">
        <h2><span style={{color:"#FFF"}}>Our Testimonials</span></h2>
    </div>
    
    
    
            <div class="carousel arrows-visibile testimonial testimonial-single" data-items="1" data-loop="true" data-autoplay="true" data-autoplay-timeout="3500" data-arrows="false" data-animate-in="fadeIn" data-animate-out="fadeOut">
            
                
                <div class="testimonial-item">
                    
                    <p style={{color:"#FFF;"}}>I recently moved to Ram Electricals recommended by a friend.  They took care of all electrical problems I had with my new home. They are reliable and trustworthy. And I am so glad to found them. </p>
                   
                    <span style={{color:"#f47629;"}}>Shafi</span>
                  
                    
                </div>
               
                <div class="testimonial-item">
                    
                    <p style={{color:"#FFF;"}}>Have had poor service with past electrical companies. Last year I found Ram Electricals and had them to do some work on my house. They are courteous and the work they did on my house is excellent. I keep their number for future needs.  </p>
                    
                    <span style={{color:"#f47629;"}}>Srinivas Reddy</span>
                   
                </div>

                <div class="testimonial-item">
                    
                    <p style={{color:"#FFF;"}}>I own a company and had electric problem one early morning. I called Ram Electricals and explained my problem, and got a call after 15 mins said that electrician was on his way to my area. I never had such great service and amazed how fast how was he diagnosed and repaired the problem. The price was very cheap. Thanks Ram Electricals. I recommend you to everyone.</p>
                    
                   
                    <span style={{color:"#f47629;"}}>Vishnu Vardhan Reddy</span>
                  
                    
                </div>
              
            </div>
  
 </section>

 <section> 
    <div class="container">
        <p> <img src="images/trusted.jpg" class="img-responsive"/> </p>
    </div>
 </section>
    </div>
 
  )
}

export default FansRepairing
