import React from 'react'
import SildeHeader from './SildeHeader'

const House_wiring = () => {
  return (
    <div>
    <section id="page-title" class="" data-parallax-image="images/contact-banner.jpg" style={{backgroundPosition:"right;"}}>
    <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(images/contact-banner.html) repeat scroll 0% 0px;"}}></div>
        <div class="container">
            <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                <div class="innerBanerText text-center">
                    <span class="firstColor"><span style={{color:"#FFF;"}}> House Wiring </span>   </span> <span class="secondColor">  </span>
                    <br/>
                    <span class="secondColor">  </span>
                </div>
            </div>
        </div>
    </section>

    <section class="p-t-40 ">
    <div class="container">
      <div class="col">
      <SildeHeader/>
      <div class="col-md-9" >
                	
      <h2 style={{color:"#f47629;"}}> House Wiring </h2>
      <p> <img src="images/wiring-ser-hero.jpg" class="img-responsive" style={{border: "1px #CCC solid;"}}/> </p>
      
      <p style={{color:"#222;"}}> Never hesitate when it comes to potential electrical issues. Electrical problems can become in to major issues. We offer various electrical services for both residential and commercial. Below you will find list of services provided by us. If you could not see what you are looking for, just give us a call to discuss it with our electrician and we ensure we will be able to help you.  </p>
      
      
      <p style={{color:"#222;"}}> With sincerity of our workers, we carved a niche for ourselves in providing high quality residential wiring services. The wiring work in your building is carried out under the guidance of our experts using best grade tools. Our professionals ensure services provided by us are executed in an excellent manner. In addition, our residential wiring services are rendered to clients at pocket friendly prices. </p>

<p style={{color:"#222;"}}> To provide hassle free service, we accept payment via various modes. We have developed a wide client base. All our house repairing services are acknowledged for well planned execution, timely completion and flexible service. Our services are best in market and widely used by residential areas, commercial areas, schools and colleges. Our professionals are competent to offer best services in quick time to meet complete satisfaction of our clients. </p>


<a href="tel:8077115322" class="btn btn-color " ><span>Order Service Now</span></a>
<a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a>
  </div>

</div>
</div>
</section>
    </div>
  )
}

export default House_wiring
