import React from 'react'
import SildeHeader from './SildeHeader'

const Cooler = () => {
  return (
    <div>

    <section id="page-title" class="" data-parallax-image="images/contact-banner.jpg" style={{backgroundPosition:"right;"}}>
    <div class="parallax-container" data-velocity="-.090" style={{background: "rgba(0, 0, 0, 0) url(images/contact-banner.html) repeat scroll 0% 0px;"}}></div>
        <div class="container">
            <div class="animated fadeInUp visible" data-animation="fadeInUp" data-animation-delay="1300">
                <div class="innerBanerText text-center">
                    <span class="firstColor"><span style={{color:"#FFF;"}}> Coolers Repairing </span>   </span> <span class="secondColor">  </span>
                    <br/>
                    <span class="secondColor">  </span>
                </div>
            </div>
        </div>
    </section>
    <section class="p-t-40 ">
    <div class="container">
      <div class="col">
    <SildeHeader/>
    <div class="col-md-9" >
                	
    <h2 style={{color:"#f47629;"}}> Coolers Repairing </h2>
    <p> <img src="images/coolers-ser-hero.jpg" class="img-responsive" style={{border: "1px #CCC solid;"}}/> </p>
    
    <p style={{color:"#222;"}}> If you need cooler repairing services, you can contact us. Ours is a Miryalaguda based service provider, specialized in repairing all makes and models of coolers. With us, customers can easily book for a service at their convenient time. Our professionals will be at your service on time. We also sales air coolers, cooler parts for quick solutions.  </p>
    
    
    <p style={{color:"#222;"}}> We provide best air cooler repairing service. Our experienced repair experts are at your door step by a phone call. We are known for providing air repairing services at low prices. We provide installation and repair services of air coolers.We provide various air cooler services namely personal air coolers, desert air coolers, window air coolers, turbo air cooler and mini air cooler repair service. </p>

<p style={{color:"#222;"}}> We deal with all brands like LG, Bajaj, Hitachi, Voltas, Carrier, York, Panasonic, Symphony, Usha, Kenstar and more. Our technicians are experts in all kinds of air coolers. We provide door to door repair service. We fix Cooler fan repair, Cooler pump repair, Cooler motor repair, Noise and piping issues and Water leakage issues. </p>

<p style={{color:"#222;"}}><b> How to fix Cooler pump repairing? </b> <br/>
If your air cooler water pump engine runs and it will not pump water, contact Ram Electrical Services for Cooler Pump Repairing. <br/><br/>
<b> How to repair Air cooler motor repair? </b> <br/>
Ram Electrical Services repair air cooler motors. Get Air Cooler motor repair service at low prices. We repair all types of air cooler motors. <br/><br/>
<b> Call Symphony Cooler Service? </b> <br/>
Book Symphony Cooler Service in Hyderabad at your doorstep. If you are looking for Symphony Cooler Service in Hyderabad reach Ram Electrical Services. 

</p>



<a href="tel:8077115322" class="btn btn-color " ><span>Order Service Now</span></a>
<a href='tel:8077115322' style={{color:'white'}}> +91-8077115322</a>
</div>
</div>
</div>
</section>
</div>
  )
}

export default Cooler
